<template>
	<div class="card card-custom">
		<div class="card-header border-0 py-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Administration.Users.title') }}</span>
			</h3>
			<div class="card-toolbar">
				<a href="" v-on:click.prevent="addRow" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.users.add') ? 'disabled' : ''">
					<i class="fas fa-plus text-primary"></i>
				</a>
			</div>
		</div>
		<div class="card-body">
			<vue-loaders-square-spin v-if="pageProcesses.isLoading" color="#000"></vue-loaders-square-spin>
			<div class="row" v-if="!pageProcesses.isLoading">
				<div class="col-12 prinor-table">
					<b-table ref="textTable" :data="users" :paginated="true" :per-page="5" default-sort="first_name" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
						<b-table-column field="first_name" :label="$t('VIEWS.Account.firstName')" v-slot="props" :sortable="true" :searchable="true" :cell-class="'grid'">
							<template>
								<b-form-input type="text" v-model="props.row.first_name" :disabled="!hasPermission('action.users.edit')"></b-form-input>
							</template>
						</b-table-column>
						<b-table-column field="last_name" :label="$t('VIEWS.Account.lastName')" v-slot="props" :sortable="true" :searchable="true" :cell-class="'grid'">
							<template>
								<b-form-input type="text" v-model="props.row.last_name" :disabled="!hasPermission('action.users.edit')"></b-form-input>
							</template>
						</b-table-column>
						<b-table-column field="email" :label="$t('VIEWS.Account.mail')" v-slot="props" :sortable="true" :searchable="true" :cell-class="'grid'">
							<template>
								<b-form-input type="text" v-model="props.row.email" :disabled="!hasPermission('action.users.edit')"></b-form-input>
							</template>
						</b-table-column>
						<b-table-column field="role" :label="$t('VIEWS.Administration.Users.role')" v-slot="props" :sortable="false" :searchable="false" :cell-class="'grid'">
							<template>
								<b-form-select v-model="props.row.role_id" :options="roles" class="mb-3" value-field="id" text-field="name" :disabled="!hasPermission('action.users.edit')"></b-form-select>
							</template>
						</b-table-column>
						<b-table-column field="company" :label="$t('VIEWS.Administration.Users.company')" v-slot="props" :sortable="false" :searchable="false" :cell-class="'grid'">
							<template>
								<b-form-select v-model="props.row.company_id" :options="companies" class="mb-3" value-field="id" text-field="name" :disabled="!hasPermission('action.users.edit')"></b-form-select>
							</template>
						</b-table-column>
						<b-table-column field="action" :label="$t('GENERAL.General.actions')" v-slot="props" :sortable="false" :searchable="false" :cell-class="'grid'">
							<template>
								<a href="" class="btn btn-icon btn-light btn-sm mx-1" v-on:click.prevent="onDelete(props.row.id)" :class="!hasPermission('action.users.delete') ? 'disabled' : ''">
									<i class="far fa-trash-alt text-primary"></i>
								</a>
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PERMISSION } from '@/core/services/permissions.service';
import { v4 as uuidv4 } from 'uuid';
import { PRINOR_ACTIONS, PRINOR_PROCESSES } from '@/core/services/actions.service';
import { mapGetters } from 'vuex';
import { SET_ACTION } from '@/core/services/store/actions.module';

export default {
	name: 'Users',
	data() {
		return {
			users: [],
			roles: [],
			companies: [],
			saveButtonId: '',
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.Administration.Administration'),
				route: '/administration',
			},
			{ title: this.$t('MENU.Administration.Users') },
		]);

		this.saveButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Forms.save'), this.onUpdate, 'success', '', false, !this.hasPermission('action.users.save'));
		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses']),
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		addRow() {
			this.users.push({
				id: uuidv4(),
				first_name: '',
				last_name: '',
				email: '',
				role_id: '',
				company_id: '',
				is_new: true,
			});
		},
		onUpdate() {
			PRINOR_PROCESSES.isUpdating();
			this.update().then(() => {
				this.onGet();
			});
		},
		onDelete(userId) {
			let that = this;

			Swal.fire({
				title: this.$t('VIEWS.Administration.Users.deleteTitle'),
				text: this.$t('VIEWS.Administration.Users.deleteConfirmation'),
				icon: 'warning',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					PRINOR_PROCESSES.isDeleting();

					let user = this.getUser(userId);
					if (user != null) {
						if (user.is_new === true) {
							this.users = this.users.filter(value => {
								return value.id !== userId;
							});
						} else {
							that.delete(userId).then(() => {
								this.onGet();
							});
						}
					}
				}
			});
		},
		onGet() {
			PRINOR_PROCESSES.isLoading();
			this.get().then(data => {
				this.users = data.data.users;
				this.roles = data.data.roles;
				this.companies = data.data.companies;

				this.roles.forEach(role => {
					if (role.organization_id === null || role.organization_id === '') {
						role.name = this.$t('GENERAL.General.standard') + ': ' + role.name;
					}
				});
			});
		},
		get() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('users/all').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
				});
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('users/', this.users).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}

						resolve(data.data);
					} else {
						PRINOR_TOASTS.save.error(this, data.data.error);
					}
				});
			});
		},
		delete(userId) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('users/' + userId).then(data => {
					PRINOR_PROCESSES.reset();
					resolve(data.data);
				});
			});
		},
		getUser(userId) {
			let filtered = this.users.filter(value => {
				return value.id === userId;
			});
			if (filtered.length === 1) {
				return filtered[0];
			} else {
				return null;
			}
		},
	},
	watch: {
		pageProcesses: {
			deep: true,
			handler: function(newVal) {
				PRINOR_ACTIONS.setProcessing(this, this.saveButtonId, newVal.isUpdating);
			},
		},
	},
};
</script>

<style scoped></style>
